export default [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/account/register.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: { authRequired: "NoLogin" },
        component: () => import('../views/auth/create/reset')
    },
    {
        path: '/twostep',
        name: 'twostep-basic',
        meta: { authRequired: "PartialLogin" },
        component: () => import('../views/auth/twostep/basic')
    },
    {
        path: "/docutrap-list",
        name: "docutrap-list",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/doc-list"),
    },
    {
        path: "/docutrap-alerts",
        name: "docutrap-alerts",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato" },
        component: () => import("../views/apps/projects/doc-alerts"),
    },
    {
        path: "/docutrap-log",
        name: "docutrap-log",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato" },
        component: () => import("../views/apps/projects/doc-log"),
    }, //su questo bisognerà aggiungere , abbonamentoRequired: "SiAbbonato" 
    {
        path: "/docutrap-selection",
        name: "docutrap-selection",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/doc-selection"),
    }, //su questo bisognerà aggiungere , abbonamentoRequired: "SiAbbonato" 
    {
        path: "/create-docutrap-strutturato",
        name: "doc-strutturato",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiDisponibilita: true },
        component: () => import("../views/apps/projects/doc-strutturato"),
    }, //su questo bisognerà aggiungere , abbonamentoRequired: "SiAbbonato"
    {
        path: "/create-docutrap-lista",
        name: "projects-docutrap-lista",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiDisponibilita: true },
        component: () => import("../views/apps/projects/docutrap-lista"),
    }, //su questo bisognerà aggiungere , abbonamentoRequired: "SiAbbonato"
    {
        path: "/create-docutrap-vuoto",
        name: "projects-docutrap-vuoto",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiDisponibilita: true },
        component: () => import("../views/apps/projects/docutrap-vuoto"),
    }, //su questo bisognerà aggiungere , abbonamentoRequired: "SiAbbonato"
    // {
    //     path: "/upgrade-abbonamento",
    //     name: "upgrade-abbonamento",
    //     meta: { title: `Sezione upgrade abbonamento - ${config.nomeSito}`, authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato" },
    //     component: () => import("../views/apps/projects/upgrade-abbonamento"),
    // },
    {
        path: "/acquisto-abbonamento",
        name: "acquisto-abbonamento",
        meta: { authRequired: "FullLogin", },
        // component: () => import("../views/apps/projects/acquisto-abbonamento"),
        component: () => import("../views/apps/projects/nuovo-abbonamento"),
    },
    {
        path: "/gagagugu",
        name: "nuovo-abbonamento",
        meta: { authRequired: "FullLogin", },
        // component: () => import("../views/apps/projects/acquisto-abbonamento"),
        component: () => import("../views/apps/projects/acquisto-abbonamento"),
    },
    // {
    //     path: "/acquisto-abbonamento",
    //     name: "acquisto-abbonamento",
    //     meta: { authRequired: "FullLogin", },
    //     component: () => import("../views/apps/projects/acquisto-abbonamento"),
    // },
    {
        path: "/profilo",
        name: "profilo",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/profilo"),
    },
    {
        path: "/profilo-setup",
        name: "profilo-setup",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/profilo-setup"),
    },
    {
        path: '/guida-telegram',
        name: 'guida-telegram',
        component: () => import('../views/guida-telegram.vue')
    },
    {
        path: '/terms-of-service',
        name: 'termsOfService',
        component: () => import('../views/condizoni-utilizzo.vue')
    },
    {
        path: '/success-order',
        name: 'successOrder',
        component: () => import('../views/success-order.vue'),
        meta: { authRequired: "NoLogin" },
    },

    // {
    //     path: '/vuoto',
    //     name: 'vuoto',
    //     meta: { authRequired: "FullLogin", },
    //     component: () => import('../views/apps/projects/vuoto.vue')
    // },
    {
        path: "/:catchAll(.*)",
        redirect: "/login"
    }
];